import apiClient from "./api-client";
import { User } from "@/types/user";

class UsersService {
    private _rolesPromise = undefined as any;

    query(usersType?: string): Promise<Array<User>> {
        const path = `v1/k8s/users` + (usersType ? `?usersType=${usersType}` : ``);
        return apiClient.asyncRequestWithResponse(path, "GET") as Promise<Array<User>>;
    }

    queryByMe(usersType?: string): Promise<Array<User>> {
        const path = `v1/k8s/users?onlyCreatedByMe=true` + (usersType ? `?usersType=${usersType}` : ``);
        return apiClient.asyncRequestWithResponse(path, "GET") as Promise<Array<User>>;
    }

    getAllRoles(): Promise<Array<any>> {
        if (!this._rolesPromise) {
            this._rolesPromise = apiClient.asyncRequestWithResponse(`v1/k8s/users/roles`, "GET") as Promise<Array<any>>;
        }
        return this._rolesPromise;
    }

    getUserRoles(userId: string): Promise<Array<any>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/users/${userId}/roles`, "GET") as Promise<Array<any>>;
    }

    deleteUser(userId: string, usersType?: string) {
        const path = `v1/k8s/users/${userId}` + (usersType ? `?usersType=${usersType}` : ``);
        return apiClient.asyncRequestWithoutResponse(path, "DELETE");
    }

    createUser(user: User) {
        return apiClient.asyncRequestWithoutResponse(`v1/k8s/users`, "POST", user);
    }

    updateUser(user: User) {
        return apiClient.asyncRequestWithoutResponse(`v1/k8s/users/${user.userId}`, "PUT", user);
    }
}

const usersService = new UsersService();
export default usersService;
