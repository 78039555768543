import {DataGridModelProps, ModelMeta, createModelMeta, AddDefaultSort} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import groupsService from "@/services/groups-service";
import RolesDetails from "@/components/users/roles-details";
import { Group } from "@/types/user";
import { computed, ComputedRef } from "vue";

const groupsColumns: DataGridColumn[] = [
    {
        key: "id",
        label: "Name",
        dataKey: "userId",
        dataTransform: (userId) => userId,
        primary: true,
        searchable: true,
        sortable: true,
        display: {
            table: { width: 500 },
        },
    },
    {
        key: "roles",
        label: "Roles",
        dataKey: "roles",
        dataTransform: (roles) => roles.map((r: any) => RolesDetails[r].displayName).join(", "),
        sortable: false,
        searchable: true,
        display: {
            table: {
                width: 230,
            },
        },
    },
];

export const columns = groupsColumns;

export type ModalType = Group;
const type = "group";
export const meta: ModelMeta<ModalType> = createModelMeta({
    icon: `raicon-${type}`,
    type,
    display: ({ userId }) => `${userId || ""}`,
});

export const createModelProps = (
    props: { dataKey: string },
    isOnlyCreatedByMe: ComputedRef<boolean> = computed(() => false),
): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => AddDefaultSort(groupsColumns, type));
    return {
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        get columns() {
            return computedColumns.value;
        },
        syncServer: false,
        fetchInterval: 60000,
        fetch: async () => {
            let data;
            if (isOnlyCreatedByMe.value) {
                data = await groupsService.queryByMe();
            } else {
                data = await groupsService.query();
            }
            return { data };
        },
    };
};
