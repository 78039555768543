const NL = escape("\n");

export function resetPassTemp({ to, userName, email, password, appUrl }: any) {
    return baseTemp({
        to,
        appUrl,
        subject: `Your new password for RunAI app`,
        msg: `Go to ${appUrl} and enter with the email and password above:${NL} Email: ${email} ${NL}Password: ${password}`,
    });
}

export function newPassTemp({ to, userName, email, password, appUrl }: any) {
    return baseTemp({
        to,
        appUrl,
        subject: `Your user details for RunAI app`,
        msg: `Go to ${appUrl} and enter with the email and password above:${NL} Email: ${email} ${NL}Password: ${password}`,
    });
}

export function baseTemp({ to, subject, msg, appUrl }: any) {
    return `mailto:${to}?subject=${subject}&body=${msg}`;
}
