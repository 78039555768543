import {DataGridModelProps, ModelMeta, createModelMeta, AddDefaultSort} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { dateFormat } from "@/core-ui/helpers/data-formats";
import usersService from "@/services/users-service";
import RolesDetails from "@/components/users/roles-details";
import { EntityType, User } from "@/types/user";
import { computed, ComputedRef } from "vue";

const usersTableColumns: DataGridColumn[] = [
    {
        key: "email",
        label: "Email",
        dataKey: "email",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
            },
        },
    },
    {
        key: "last_login",
        label: "Last Login",
        dataKey: "lastLogin",
        dataTransform: dateFormat,
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 170,
            },
        },
    },
    {
        key: "roles",
        label: "Roles",
        dataKey: "roles",
        dataTransform: (roles) => roles.map((r: any) => RolesDetails[r].displayName).join(", "),
        sortable: false,
        searchable: true,
        display: {
            table: {
                width: 230,
            },
        },
    },
    {
        key: "created",
        label: "Created",
        dataKey: "createdAt",
        searchable: true,
        sortable: true,
        dataTransform: dateFormat,
        display: {
            table: {
                width: 180,
            },
        },
    },
    {
        key: "id",
        label: "ID",
        dataKey: "userId",
        primary: true,
        searchable: true,
        sortable: true,
        display: null,
    },
];

export const columns = usersTableColumns;

export type ModalType = User;
const type = "user";
export const meta: ModelMeta<ModalType> = createModelMeta({
    icon: `raicon-${type}`,
    type,
    display: ({ email, userId }) => `${email || userId || ""}`,
});

export const createModelProps = (
    props: { dataKey: string },
    isOnlyCreatedByMe: ComputedRef<boolean> = computed(() => false),
): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => {
        return AddDefaultSort(columns, type)
    });
    const finalColumns = computed(() =>
            [
                ...([
                        {
                            key: "name",
                            label: "User",
                            dataKey: "name",
                            sortable: true,
                            searchable: true,
                            display: {
                                table: {
                                    width: 200,
                                },
                            },
                        },
                    ]
                ),
                ...computedColumns.value,
            ],
    );
    return {
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        get columns() {
            return finalColumns.value;
        },
        syncServer: false,
        fetchInterval: 60000,
        fetch: async () => {
            let data;
            if (isOnlyCreatedByMe.value) {
                data = await usersService.queryByMe(EntityType.REGULAR_USER);
            } else {
                data = await usersService.query(EntityType.REGULAR_USER);
            }
            return { data };
        },
    };
};
