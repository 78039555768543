
import Modal from "@/core-ui/Modal.vue";
import CircleLoader from "@/core-ui/CircleLoader.vue";
import { dispatcher, RequestState } from "@/core-ui/services/dispatcher";
import { Tabs, User } from "@/types/user";
import InputField from "@/core-ui/forms/components/InputField.vue";
import { emailValidation, nameValidation, passwordValidation } from "@/core-ui/helpers/validations-schemas";
import usersService from "../../services/users-service";
import groupsService from "../../services/groups-service";
import RolesDetails from "./roles-details";
import FormOptionsBox from "@/components/users/FormOptionsBox.vue";
import clustersStore from "@/stores/clusters-store";
import { createEditableUser } from "@/helpers/users";
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import authStore, { RolesEnum } from "@/stores/authStore";

export default defineComponent({
    components: {
        FormOptionsBox,
        InputField,
        Modal,
        CircleLoader,
    },
    emits: ["submit", "cancel"],
    props: {
        isNew: Boolean,
        user: Object as PropType<User>,
        isSso: Boolean,
        usersScreen: String
    },

    setup(props, ctx) {
        const submitted = ref<boolean>(false);
        const editingUser = reactive<User>({} as any);

        watch(
            () => props.user,
            (user) => {
                const newUser = createEditableUser(user, props.usersScreen);
                Object.assign(editingUser, newUser);
            },
            { immediate: true },
        );

        const userRolesLoaded = ref<boolean>(true);
        const isRuleDisabled = (role: string): boolean => {
            if (role == RolesEnum.RESEARCHER) {
                return (
                    editingUser.roles?.some(
                        (role: string) => role == RolesEnum.RESEARCH_MANAGER || role == RolesEnum.ML_ENGINEER,
                    ) || false
                );
            } else if (role == RolesEnum.RESEARCH_MANAGER || role == RolesEnum.ML_ENGINEER) {
                return editingUser.roles?.some((role: string) => role == RolesEnum.RESEARCHER) || false;
            }
            return false;
        };

        const self = {
            Tabs,
            formId: "create-user-form" + Math.random(),
            submitted,
            editingUser,
            userRolesLoaded,
            title: (props.isNew ? "New" : "Edit") + " " + (props.usersScreen === Tabs.groups ? "Group" : "User"),
            emailValidation,
            nameValidation,
            passwordValidation,
            rolesRequest: dispatcher.dispatch(usersService.getAllRoles()) as RequestState,

            get clusters() {
                return (clustersStore.state.clusters || []).map((cluster) => {
                    return {
                        id: cluster.uuid,
                        title: cluster.name,
                    };
                });
            },

            get rolesOptions() {
                const roles = this.rolesRequest.results || [];

                const isDepartmentAdmin = !authStore.userInfo.roles.includes('admin') && authStore.userInfo.roles.includes('department_admin');
                return roles.filter((role: any) => {
                    if (isDepartmentAdmin) {
                        return role == RolesEnum.RESEARCHER || role == RolesEnum.ML_ENGINEER
                    }
                    return true
                }).map((role: any) => {
                    return {
                        id: role,
                        disabled: isRuleDisabled(role),
                        title: RolesDetails[role].displayName,
                        description: RolesDetails[role].description,
                        aid: "user-role-checkbox-" + role,
                    };
                });
            },

            get invalid() {
                return !(
                    (editingUser.email || editingUser.username) &&
                    editingUser.roles &&
                    editingUser.roles.length &&
                    self.clusterSelectedValid
                );
            },

            get clusterSelectedValid() {
                return editingUser.permitAllClusters || !!editingUser.permittedClusters?.length;
            },

            get hasAdminRole() {
                const roles = this.rolesRequest.results || [];
                const userRoles: any = editingUser ? editingUser.roles : [];
                const adminRole = roles.find((role: any) => role.name === "admin");
                return adminRole && userRoles.includes(adminRole.id);
            },

            cancel() {
                ctx.emit("cancel");
            },

            async submit() {
                submitted.value = true;

                try {
                    if (!props.isNew) {
                        if (props.usersScreen === Tabs.groups) {
                            await dispatcher.executeWithErrorHandling(groupsService.updateGroup(editingUser));
                        } else {
                            await dispatcher.executeWithErrorHandling(usersService.updateUser(editingUser));
                        }
                    }
                    ctx.emit("submit", editingUser);
                } catch (e) {
                    submitted.value = false;
                }
            },
        };

        watch(
            () => self.hasAdminRole,
            () => {
                if (self.hasAdminRole) {
                    editingUser.permitAllClusters = true;
                }
            },
        );
        return self;
    },
});
