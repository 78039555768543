import { ActionOn, createCRUDActions, DataGridAction, DataGridModel, DeleteModalAction, handleAction, ModalAction, useDataGridModel, useDeleteModalAction, useModalAction } from '@/core-ui/data-grid/compositions';
import authStore from '@/stores/authStore';
import { ComputedRef } from 'vue';
import { useToast } from 'vue-toastification';
import { createModelProps as createModelPropsUser, meta as metaUser } from '@/models/users.model';
import usersService from '@/services/users-service';
import { EntityType, User } from '@/types/user';

export class UsersModelLogic {
    model: DataGridModel<User>;
    initModal: ModalAction<any>;
    editModal: ModalAction<any>;
    deleteModal: DeleteModalAction<any>;
    actions: DataGridAction[];
    toast = useToast();


    constructor(isOnlyCreatedByMeFilter: ComputedRef<boolean>) {
        this.model = this.getUsersModel(isOnlyCreatedByMeFilter);
        this.initModal = this.getInitUsersModal();
        this.editModal = this.getEditUsersModal();
        this.deleteModal = this.getDeleteUsersModal();
        this.actions = this.getActions();
    }
    
    private getUsersModel(isOnlyCreatedByMeFilter: ComputedRef<boolean>): DataGridModel<User> {
        return useDataGridModel(createModelPropsUser({ dataKey: metaUser.type }, isOnlyCreatedByMeFilter));
    }

    private getInitUsersModal(): ModalAction<any> {
        return useModalAction({
            submit: this.model.refresh,
            mapHandleData: (item = {}) => ({ ...item }),
        });
    }

    private getEditUsersModal(): ModalAction<any> {
        return useModalAction({
            submit: async (permission) => {
                if (permission && !permission.userId) {
                    await handleAction(
                        'Initalize user',
                        this.initModal.handle.bind(null, {
                            user: permission,
                            isNew: true,
                        }) as any,
                        this.toast,
                    );
                }
                if (permission.email == authStore.userEmail) {
                    if (permission.roles?.every((r: string) => r !== 'admin')) {
                        return authStore.logout();
                    } else {
                        authStore.refreshUserInfo();
                    }
                }
                return this.model.refresh();
            },
            mapHandleData: (item = {}) => ({ ...item }),
        });
    }

    private getDeleteUsersModal(): DeleteModalAction<any> {
        return useDeleteModalAction<any>({
            modelMeta: metaUser,
            delete: (props) =>
                this.deleteUsers(props).then(() => {
                    this.model.refresh();
                }),
        });
    }

    private async deleteUsers(itemsToDelete?: any[]): Promise<any[] | undefined> {
        if (!itemsToDelete) {
            return;
        }
        return Promise.all(itemsToDelete.map((user: any) => usersService.deleteUser(user.userId, EntityType.REGULAR_USER)));
    }

    private getActions() {
        const isPermitted = authStore.userInfo.roles.includes('admin') || authStore.userInfo.roles.includes('department_admin');
        const actions = [
            ...createCRUDActions(
                metaUser,
                {
                    create: this.editModal.handle,
                    update: this.editModal.handle,
                    remove: this.deleteModal.handle as any,
                },
                isPermitted,
            ),
        ];
        actions.push({
            on: ActionOn.Item,
            func: (user: any) => {
                return this.initModal.handle({
                    user,
                    isNew: false,
                });
            },
            key: 'reset_password',
            label: 'Reset password',
            aid: 'reset-password',
            icon: 'raicon-reset',
            permitted: isPermitted,
        });
        return actions;
    }
}