
import IconButton from "@/core-ui/IconButton.vue";
import { copy } from "@/core-ui/helpers/copy";
import { Options, Vue } from "vue-class-component";

@Options({ components: { IconButton } })
export default class Copyable extends Vue {
    copy() {
        const text = (this.$refs.content as any).innerText;
        copy(text);
    }
}
