
import Modal from "@/core-ui/Modal.vue";
import Note from "@/core-ui/Note.vue";
import Button from "@/core-ui/Button.vue";
import TextInput from "@/core-ui/TextInput.vue";
import TextButton from "@/core-ui/TextButton.vue";
import Copyable from "@/core-ui/Copyable.vue";
import { newPassTemp } from "./user-email-template";
import usersService from "../../services/users-service";
import groupsService from "../../services/groups-service";
import { Tabs, User } from "@/types/user";
import { createEditableUser } from "@/helpers/users";
import { computed, defineComponent, PropType, Ref, ref, watch } from "vue";

export default defineComponent({
    components: { Modal, TextInput, Button, Note, TextButton, Copyable },
    props: {
        user: { type: Object as PropType<User> },
        isNew: { type: Boolean, default: true },
        usersScreen: String,
    },
    setup(props, ctx) {
        const screenTypeToTitle = new Map([[Tabs.users, "Create user"], [Tabs.ssoUsers, "Add permission"], [Tabs.groups, "Create group"]]);
        const state = ref<string>(null as any);
        const editableUser = computed<User>(() => {
            return createEditableUser(props.user, props.usersScreen);
        });
        const sendToEmail = computed<string>(() => editableUser.value?.email);
        const password = ref<string>("");

        const self = {
            Tabs,
            state,
            sendToEmail,
            password,
            editableUser,
            get title() {
                return props.isNew ? screenTypeToTitle.get(props.usersScreen as Tabs) : "Reset password";
            },

            get userDisplay() {
                const { email } = editableUser.value;
                return `${email}`;
            },

            get isEnsure() {
                return state.value == "ensure";
            },

            get isLoading() {
                return state.value == "loading";
            },

            get isFailed() {
                return state.value == "failed";
            },

            get isSuccess() {
                return state.value == "success";
            },

            sendEmail() {
                const a = document.createElement("a");
                a.href = newPassTemp({
                    to: sendToEmail.value,
                    userName: editableUser.value.name,
                    email: editableUser.value.email,
                    password: password.value,
                    appUrl: `${location.protocol}//${location.host}`,
                });
                a.target = "_blank";
                a.click();
            },

            resetPass() {
                state.value = "loading";
                // create a random password
                const password = self.geerateRandomPassword();
                const func = props.isNew ? (props.usersScreen === Tabs.groups ? groupsService.createGroup: usersService.createUser) : (props.usersScreen === Tabs.groups ? groupsService.updateGroup : usersService.updateUser);
                // update the new password
                func({
                    ...editableUser.value,
                    password,
                    needToChangePassword: true,
                }).then(
                    () => {
                        self.password.value = password;
                        state.value = "success";
                    },
                    () => {
                        state.value = "failed";
                    },
                );
            },

            geerateRandomPassword() {
                return Math.random() // Generate random number, eg: 0.123456
                    .toString(36) // Convert  to base-36 : "0.4fzyo82mvyr"
                    .slice(-8) // Cut off last 8 characters : "yo82mvyr"
                    .concat("Ii2!"); // adding spchial character
            },
        };

        if (props.isNew) {
            self.resetPass();
        } else {
            state.value = "ensure";
        }

        return self;
    },
});
