import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditPermission = _resolveComponent("EditPermission")!
  const _component_ResetUserModal = _resolveComponent("ResetUserModal")!
  const _component_DeleteModalAction = _resolveComponent("DeleteModalAction")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_ButtonSelect = _resolveComponent("ButtonSelect")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      (_ctx.groupModel.editModal.isOpening)
        ? (_openBlock(), _createBlock(_component_EditPermission, {
            key: 0,
            user: _ctx.groupModel.editModal.handleData,
            "is-new": !_ctx.groupModel.editModal.handleData?.userId,
            "is-sso": _ctx.isSSO,
            "users-screen": _ctx.Tabs.groups,
            onCancel: _ctx.groupModel.editModal.cancel,
            onSubmit: _ctx.groupModel.editModal.submit
          }, null, 8, ["user", "is-new", "is-sso", "users-screen", "onCancel", "onSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.groupModel.initModal.isOpening)
        ? (_openBlock(), _createBlock(_component_ResetUserModal, {
            key: 1,
            user: _ctx.groupModel.initModal.handleData?.user,
            "users-screen": _ctx.Tabs.groups,
            "is-new": _ctx.groupModel.initModal.handleData?.isNew,
            onCancel: _ctx.groupModel.initModal.cancel
          }, null, 8, ["user", "users-screen", "is-new", "onCancel"]))
        : _createCommentVNode("", true),
      (_ctx.usersModel.editModal.isOpening)
        ? (_openBlock(), _createBlock(_component_EditPermission, {
            key: 2,
            user: _ctx.usersModel.editModal.handleData,
            "is-new": !_ctx.usersModel.editModal.handleData?.userId,
            "is-sso": _ctx.isSSO,
            "users-screen": _ctx.Tabs.users,
            onCancel: _ctx.usersModel.editModal.cancel,
            onSubmit: _ctx.usersModel.editModal.submit
          }, null, 8, ["user", "is-new", "is-sso", "users-screen", "onCancel", "onSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.usersModel.initModal.isOpening)
        ? (_openBlock(), _createBlock(_component_ResetUserModal, {
            key: 3,
            user: _ctx.usersModel.initModal.handleData?.user,
            "users-screen": _ctx.Tabs.users,
            "is-new": _ctx.usersModel.initModal.handleData?.isNew,
            onCancel: _ctx.usersModel.initModal.cancel
          }, null, 8, ["user", "users-screen", "is-new", "onCancel"]))
        : _createCommentVNode("", true),
      (_ctx.ssoUsersModel.editModal.isOpening)
        ? (_openBlock(), _createBlock(_component_EditPermission, {
            key: 4,
            user: _ctx.ssoUsersModel.editModal.handleData,
            "is-new": !_ctx.ssoUsersModel.editModal.handleData?.userId,
            "is-sso": _ctx.isSSO,
            "users-screen": _ctx.Tabs.ssoUsers,
            onCancel: _ctx.ssoUsersModel.editModal.cancel,
            onSubmit: _ctx.ssoUsersModel.editModal.submit
          }, null, 8, ["user", "is-new", "is-sso", "users-screen", "onCancel", "onSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.ssoUsersModel.initModal.isOpening)
        ? (_openBlock(), _createBlock(_component_ResetUserModal, {
            key: 5,
            user: _ctx.ssoUsersModel.initModal.handleData?.user,
            "users-screen": _ctx.Tabs.ssoUsers,
            "is-new": _ctx.ssoUsersModel.initModal.handleData?.isNew,
            onCancel: _ctx.ssoUsersModel.initModal.cancel
          }, null, 8, ["user", "users-screen", "is-new", "onCancel"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DeleteModalAction, {
        state: _ctx.groupModel.deleteModal
      }, null, 8, ["state"]),
      _createVNode(_component_DeleteModalAction, {
        state: _ctx.usersModel.deleteModal
      }, null, 8, ["state"]),
      _createVNode(_component_DeleteModalAction, {
        state: _ctx.ssoUsersModel.deleteModal
      }, null, 8, ["state"]),
      (!_ctx.isSSO)
        ? (_openBlock(), _createBlock(_component_DataGrid, {
            key: 6,
            model: _ctx.usersModel.model,
            actions: _ctx.usersModel.actions,
            "sync-url": true
          }, null, 8, ["model", "actions"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
            (_ctx.currentTab === _ctx.Tabs.groups)
              ? (_openBlock(), _createBlock(_component_DataGrid, {
                  key: 0,
                  model: _ctx.groupModel.model,
                  actions: _ctx.groupModel.actions,
                  "sync-url": true
                }, {
                  aftersearch: _withCtx(() => [
                    _createVNode(_component_ButtonSelect, {
                      style: {"margin-left":"10px"},
                      options: _ctx.tabs,
                      "model-value": _ctx.currentTab,
                      "onUpdate:modelValue": _ctx.updateTab
                    }, null, 8, ["options", "model-value", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }, 8, ["model", "actions"]))
              : (_ctx.currentTab === _ctx.Tabs.users)
                ? (_openBlock(), _createBlock(_component_DataGrid, {
                    key: 1,
                    model: _ctx.usersModel.model,
                    actions: _ctx.usersModel.actions,
                    "sync-url": true
                  }, {
                    aftersearch: _withCtx(() => [
                      _createVNode(_component_ButtonSelect, {
                        style: {"margin-left":"10px"},
                        options: _ctx.tabs,
                        "model-value": _ctx.currentTab,
                        "onUpdate:modelValue": _ctx.updateTab
                      }, null, 8, ["options", "model-value", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }, 8, ["model", "actions"]))
                : (_ctx.currentTab === _ctx.Tabs.ssoUsers)
                  ? (_openBlock(), _createBlock(_component_DataGrid, {
                      key: 2,
                      model: _ctx.ssoUsersModel.model,
                      actions: _ctx.ssoUsersModel.actions,
                      "sync-url": true
                    }, {
                      aftersearch: _withCtx(() => [
                        _createVNode(_component_ButtonSelect, {
                          style: {"margin-left":"10px"},
                          options: _ctx.tabs,
                          "model-value": _ctx.currentTab,
                          "onUpdate:modelValue": _ctx.updateTab
                        }, null, 8, ["options", "model-value", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }, 8, ["model", "actions"]))
                  : _createCommentVNode("", true)
          ], 64))
    ]),
    _: 1
  }))
}