import { createCRUDActions, DataGridAction, DataGridModel, DeleteModalAction, handleAction, ModalAction, useDataGridModel, useDeleteModalAction, useModalAction } from '@/core-ui/data-grid/compositions';
import { createModelProps as createModelPropsGroup, meta as metaGroup } from '@/models/groups.model';
import authStore from '@/stores/authStore';
import { Group } from '@/types/user';
import { ComputedRef } from 'vue';
import { useToast } from 'vue-toastification';
import groupsService from '../../services/groups-service';


export class GroupsModelLogic {
    model: DataGridModel<Group>;
    initModal: ModalAction<any>;
    editModal: ModalAction<any>;
    deleteModal: DeleteModalAction<any>;
    actions: DataGridAction[];
    toast = useToast();

    constructor(isOnlyCreatedByMeFilter: ComputedRef<boolean>) {
        this.model = this.getGroupsModel(isOnlyCreatedByMeFilter);
        this.initModal = this.getInitGroupsModal();
        this.editModal = this.getEditGroupsModal();
        this.deleteModal = this.getDeleteGroupsModal();
        this.actions = this.getActions();
    }
    
    private getGroupsModel(isOnlyCreatedByMeFilter: ComputedRef<boolean>): DataGridModel<Group> {
        return useDataGridModel(createModelPropsGroup({ dataKey: metaGroup.type }, isOnlyCreatedByMeFilter));
    }

    private getInitGroupsModal(): ModalAction<Group> {
        return useModalAction({
            submit: this.model.refresh,
            mapHandleData: (item = {}) => ({ ...item }),
        });
    }

    private getEditGroupsModal(): ModalAction<Group> {
        return useModalAction({
            submit: async (permission) => {
                if (permission && !permission.userId) {
                    permission.userId = permission.username;
                    await handleAction(
                        'Initalize user',
                        this.initModal.handle.bind(null, {
                            user: permission,
                            isNew: true,
                        }) as any,
                        this.toast,
                    );
                }
                return this.model.refresh();
            },
            mapHandleData: (item = {}) => ({ ...item }),
        });
    }

    private getDeleteGroupsModal(): DeleteModalAction<Group> {
        return useDeleteModalAction<Group>({
            modelMeta: metaGroup,
            delete: (props) =>
                this.deleteGroups(props).then(() => {
                    this.model.refresh();
                }),
        });
    }

    private async deleteGroups(itemsToDelete?: Group[]): Promise<any[] | undefined> {
        if (!itemsToDelete) {
            return;
        }
        return Promise.all(itemsToDelete.map((user: any) => groupsService.deleteGroup(user.userId)));
    }

    private getActions(): DataGridAction[] {
        const actions = [
            ...createCRUDActions(
                metaGroup,
                {
                    create: this.editModal.handle,
                    update: this.editModal.handle,
                    remove: this.deleteModal.handle,
                },
                authStore.userInfo.roles.includes('admin') || authStore.userInfo.roles.includes('department_admin')
            ),
        ];
        return actions;
    }
}