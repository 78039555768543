import apiClient from "./api-client";
import { Group } from "@/types/user";

class GroupsService {
    private _rolesPromise = undefined as any;

    query(): Promise<Array<Group>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/groups`, "GET") as Promise<Array<Group>>;
    }

    queryByMe(): Promise<Array<Group>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/groups?onlyCreatedByMe=true`, "GET") as Promise<Array<Group>>;
    }

    getAllRoles(): Promise<Array<any>> {
        if (!this._rolesPromise) {
            this._rolesPromise = apiClient.asyncRequestWithResponse(`v1/k8s/groups/roles`, "GET") as Promise<Array<any>>;
        }
        return this._rolesPromise;
    }

    getGroupRoles(userId: string): Promise<Array<any>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/groups/${userId}/roles`, "GET") as Promise<Array<any>>;
    }

    deleteGroup(userId: string) {
        return apiClient.asyncRequestWithoutResponse(`v1/k8s/groups/${userId}`, "DELETE");
    }

    createGroup(group: Group) {
        return apiClient.asyncRequestWithoutResponse(`v1/k8s/groups`, "POST", group);
    }

    updateGroup(group: Group) {
        return apiClient.asyncRequestWithoutResponse(`v1/k8s/groups/${group.userId}`, "PUT", group);
    }
}

const groupsService = new GroupsService();
export default groupsService;
