import { DataGridModel, useDataGridModel } from "@/core-ui/data-grid/compositions/DataGridModel";
import { ComputedRef } from "@vue/runtime-core";
import { useToast } from "vue-toastification";
import { createModelProps as createModelPropsSSOUser} from '@/models/ssoUsers.model';
import { meta as metaUser } from '@/models/ssoUsers.model';
import { createCRUDActions, DataGridAction, DeleteModalAction, handleAction, ModalAction, useDeleteModalAction, useModalAction } from "@/core-ui/data-grid/compositions";
import authStore from '@/stores/authStore';
import usersService from "@/services/users-service";
import { EntityType } from "@/types/user";


export class SsoUsersModelLogic {
    model: DataGridModel<any>;
    initModal: ModalAction<any>;
    editModal: ModalAction<any>;
    deleteModal: DeleteModalAction<any>;
    actions: DataGridAction[];
    toast = useToast();

    constructor(isOnlyCreatedByMeFilter: ComputedRef<boolean>) {
        this.model = this.getSsoUsersModel(isOnlyCreatedByMeFilter);
        this.initModal = this.getInitModelSsoUsers();
        this.editModal = this.getEditSsoUsersModal();
        this.deleteModal = this.getDeleteSsoUsersModal();
        this.actions = this.getActions();
    }

    private getSsoUsersModel(isOnlyCreatedByMeFilter: ComputedRef<boolean>) {
        return useDataGridModel(createModelPropsSSOUser({ dataKey: metaUser.type }, isOnlyCreatedByMeFilter));
    }

    private getInitModelSsoUsers() {
        return useModalAction({
            submit: this.model.refresh,
            mapHandleData: (item = {}) => ({ ...item }),
        });
    }

    private getEditSsoUsersModal(): ModalAction<any> {
        return useModalAction({
            submit: async (permission) => {
                if (permission && !permission.userId) {
                    permission.userId = permission.username;
                    await handleAction(
                        'Initalize user',
                        this.initModal.handle.bind(null, {
                            user: permission,
                            isNew: true,
                        }) as any,
                        this.toast,
                    );
                }
                if (permission.email == authStore.userEmail) {
                    if (permission.roles?.every((r: string) => r !== 'admin')) {
                        return authStore.logout();
                    } else {
                        authStore.refreshUserInfo();
                    }
                }
                return this.model.refresh();
            },
            mapHandleData: (item = {}) => ({ ...item }),
        });
    }

    private getDeleteSsoUsersModal(): DeleteModalAction<any> {
        return useDeleteModalAction<any>({
            modelMeta: metaUser,
            delete: (props) =>
                this.deleteUsers(props).then(() => {
                    this.model.refresh();
                }),
        });
    }

    private async deleteUsers(itemsToDelete?: any[]): Promise<any[] | undefined> {
        if (!itemsToDelete) {
            return;
        }
        return Promise.all(itemsToDelete.map((user: any) => usersService.deleteUser(user.userId, EntityType.SSO_USER)));
    }

    private getActions() {
        const actions = [
            ...createCRUDActions(
                metaUser,
                {
                    create: this.editModal.handle,
                    update: this.editModal.handle,
                    remove: this.deleteModal.handle as any,
                },
                authStore.userInfo.roles.includes('admin') || authStore.userInfo.roles.includes('department_admin'),
            ),
        ];
        return actions;
    }
}


