import { EntityType, Tabs, User } from "@/types/user";

export function createEditableUser(user?: User, usersScreen?: string): User {
    if (!user || !Object.keys(user).length) {
        return {
            name: "",
            password: "",
            email: "",
            roles: [],
            permittedClusters: [],
            permitAllClusters: true,
        };
    } else {
        const editableUser = {
            roles: [],
            permittedClusters: [],
            ...(user as any),
        };
        editableUser.username = usersScreen === Tabs.users ? user.email : user?.userId;
        editableUser.entityType = usersScreen === Tabs.users ? EntityType.REGULAR_USER : EntityType.SSO_USER;
        return editableUser;
    }
}
