
import Table from "@/core-ui/data-grid/components/Table.vue";
import { BoxOption } from "@/core-ui/types/box-option";
import { defineComponent, PropType, ref, watch } from "vue";
import Note from "@/core-ui/Note.vue";

export default defineComponent({
    components: { Table, Note },
    emits: ["update:modelValue", "change-access"],
    props: {
        title: String,
        note: String,
        fullAccessOption: Boolean,
        disabled: Boolean,
        fullAccess: Boolean,
        error: String,
        options: Array as PropType<Array<BoxOption>>,
        modelValue: {
            type: Array as PropType<Array<string>>,
            default: () => [],
        },
    },

    setup(props, ctx) {
        const model = ref<Array<string>>([...props.modelValue!]);

        watch(model, () => {
            ctx.emit("update:modelValue", model.value);
        });

        const self = {
            model,

            get key() {
                return props.title!.toLowerCase();
            },

            inputId(option: BoxOption): string {
                return self.key + "-" + option.id;
            },

            changeFullAccess(event: any) {
                ctx.emit("change-access", event.target.value === "full");
            },
        };

        return self;
    },
});
