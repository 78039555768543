import {DataGridModelProps, ModelMeta, createModelMeta} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { dateFormat } from "@/core-ui/helpers/data-formats";
import usersService from "@/services/users-service";
import RolesDetails from "@/components/users/roles-details";
import { User, EntityType } from "@/types/user";
import { computed, ComputedRef } from "vue";

const ssoUsersColumns: DataGridColumn[] = [
    {
        key: "id",
        label: "Username",
        dataKey: "userId",
        dataTransform: (userId) => userId,
        primary: true,
        searchable: true,
        sortable: true,
        display: {
            table: { width: 500 },
        },
    },
    {
        key: "roles",
        label: "Roles",
        dataKey: "roles",
        dataTransform: (roles) => roles.map((r: any) => RolesDetails[r].displayName).join(", "),
        sortable: false,
        searchable: true,
        display: {
            table: {
                width: 230,
            },
        },
    },
    {
        key: "last_login",
        label: "Last Login",
        dataKey: "lastLogin",
        dataTransform: dateFormat,
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 170,
            },
        },
    },
];

export const columns = ssoUsersColumns;

export type ModalType = User;
const type = "permission";
export const meta: ModelMeta<ModalType> = createModelMeta({
    icon: `raicon-user`,
    type,
    display: ({ email, userId }) => `${email || userId || ""}`,
});

export const createModelProps = (
    props: { dataKey: string },
    isOnlyCreatedByMe: ComputedRef<boolean> = computed(() => false),
): DataGridModelProps<ModalType> => {

    const finalColumns = computed(() =>
        ssoUsersColumns
    );
    return {
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        get columns() {
            return finalColumns.value;
        },
        syncServer: false,
        fetchInterval: 60000,
        fetch: async () => {
            let data;
            if (isOnlyCreatedByMe.value) {
                data = await usersService.queryByMe(EntityType.SSO_USER);
            } else {
                data = await usersService.query(EntityType.SSO_USER);
            }
            return { data };
        },
    };
};
