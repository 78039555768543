import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, { ref: "content" }), [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("i", {
      class: "icon raicon-copy",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
    })
  ], 16))
}