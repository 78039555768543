
import Page from '@/core-ui/Page.vue';
import authStore from '@/stores/authStore';
import DataGrid from '@/core-ui/data-grid/components/DataGrid.vue';
import DeleteModalAction from '@/core-ui/data-grid/components/DeleteModal/DeleteModalAction.vue';
import ResetUserModal from '@/components/users/ResetUserDialog.vue';
import ButtonSelect from '@/core-ui/forms/components/ButtonSelect.vue';
import EditPermission from '@/components/users/EditPermission.vue';
import { defineComponent, computed, ref } from 'vue';
import { GroupsModelLogic } from "./UsersModelLogic/GroupsModelLogic";
import { UsersModelLogic } from "./UsersModelLogic/UsersModelLogic";
import { SsoUsersModelLogic } from "./UsersModelLogic/SsoUsersModelLogic";
import tenantStore from '@/stores/tenantStore';
import { Tabs } from '@/types/user';

export default defineComponent({
    components: {
        Page,
        DataGrid,
        DeleteModalAction,
        ResetUserModal,
        EditPermission,
        ButtonSelect,
    },
    setup() {
        const currentTab = ref('Users');
        const tabs = getTabsArray();
        const isSSO = computed(() => tenantStore.state.ssoEnabled);
        const isOnlyCreatedByMeFilter = computed(() => !authStore.isAdmin && authStore.isDepartmentAdmin);

        const groupModel = new GroupsModelLogic(isOnlyCreatedByMeFilter);
        const usersModel = new UsersModelLogic(isOnlyCreatedByMeFilter);
        const ssoUsersModel = new SsoUsersModelLogic(isOnlyCreatedByMeFilter);

        function getTabsArray() {
            let tabs: { value: Tabs; }[] = [];
            Object.values(Tabs).forEach(tab => {
                tabs.push({value: tab});
            })
            return tabs;
        }

        function updateTab(tab: Tabs) {
            if (!tab) return;
            currentTab.value = tab != currentTab.value ? tab : currentTab.value;
        }

        return {
            currentTab,
            Tabs,
            tabs,
            isSSO,
            groupModel,
            usersModel,
            ssoUsersModel,
            updateTab
        };
    },
});
